<template>
  <v-overlay class="download-overlay" absolute color="secondary" :value="isLoading" :opacity="0.9" z-index="4">
    <download-progress :file-name="fileName" :progress="localProgress" :is-error="isError" @repeat="repeat" />

    <v-btn v-if="isError" icon class="download-overlay__close-button" @click="closeOverlay">
      <v-icon color="primary">mdi-close</v-icon>
    </v-btn>
  </v-overlay>
</template>

<script>
import DownloadProgress from '@/components/DownloadProgress.vue';

export default {
  name: 'InvoicesDownloadOverlay',

  components: { DownloadProgress },

  props: {
    fileName: { type: String, required: true },
    progress: { type: Number, required: true },
    isError: { type: Boolean, default: false },
    isLoading: { type: Boolean, default: false },
  },

  computed: {
    localProgress() {
      if (this.isError) {
        return 100;
      }

      return this.progress;
    },
  },

  methods: {
    repeat() {
      this.$emit('repeat');
    },

    closeOverlay() {
      this.$emit('close-overlay');
    },
  },
};
</script>

<style lang="scss">
.download-overlay {
  .v-overlay__content {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__close-button {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);

    @media (max-width: map.get($--screens, 'sm')) {
      position: absolute;
      top: 8px;
      right: 8px;
      transform: none;
    }
  }
}
</style>
