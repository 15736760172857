<template>
  <base-modal
    v-model="isOpen"
    class="invoices-settings-modal"
    persistent
    :title="$t('invoice.settings_modal.title')"
    @close="close"
  >
    <div class="d-flex flex-column flex-md-row align-start align-md-center justify-start justify-md-space-between">
      <h2 class="text-subtitle-1 text-md-h6 font-weight-medium black--text">
        {{ $t('invoice.settings_modal.numbering_dependency') }}
      </h2>

      <base-switch
        v-model="automaticInvoiceNumbering"
        reverse
        hide-details
        :disabled="isEditMode || !canUpdate"
        :expand="media.isMobile"
        :label="$t('invoice.settings_modal.automatic_numbering')"
      />
    </div>

    <validation-observer ref="observer">
      <div class="d-flex align-center justify-space-between my-6">
        <h2 class="text-subtitle-1 text-md-h6 font-weight-medium black--text">
          {{ serviceTypeTitle }}
        </h2>

        <template v-if="automaticInvoiceNumbering">
          <v-btn
            v-if="isEditMode && !media.isMobile"
            type="submit"
            color="primary"
            class="elevation-0"
            :loading="settingsIsSent"
            @click="save"
          >
            {{ $t('button.save') }}
          </v-btn>

          <v-btn v-else-if="!isEditMode && canUpdate" color="primary" type="button" class="elevation-0" @click="edit">
            <v-icon left>mdi-pencil</v-icon>
            {{ $t('button.edit') }}
          </v-btn>
        </template>

        <div v-else class="invoices-settings-modal__button-stub"></div>
      </div>

      <settings-modal-service
        v-for="(service, index) in services.list"
        :key="service.value"
        class="mb-10"
        :disabled="!automaticInvoiceNumbering"
        :readonly="!isEditMode"
        :title="$t(service.name)"
        :prefix.sync="invoiceNumberTemplates[index].prefix"
        :initial-numbering.sync="invoiceNumberTemplates[index].initialNumbering"
      />
    </validation-observer>

    <template v-if="media.isMobile" #actions>
      <v-row no-gutters>
        <v-col class="pr-1" cols="6" @click="close">
          <v-btn block color="secondary" class="elevation-0 primary--text">{{ $t('button.back') }}</v-btn>
        </v-col>

        <v-col class="pl-1" cols="6">
          <v-btn block color="primary" class="elevation-0" :loading="settingsIsSent" @click="save">{{
            $t('button.save')
          }}</v-btn>
        </v-col>
      </v-row>
    </template>
  </base-modal>
</template>

<script>
// Utils
import { flushPromises } from '@/utils/scheduler';

// Http
import client from '@/http/client';

// Services
import companyService from '@/services/company';
import { getServiceTypes } from '@/services/select';

// Components
import { ValidationObserver } from 'vee-validate';
import BaseModal from '@/components/BaseModal.vue';
import BaseSwitch from '@/components/BaseSwitch.vue';
import SettingsModalService from './SettingsModalService.vue';

export default {
  name: 'InvoicesSettingsModal',

  components: { BaseModal, BaseSwitch, SettingsModalService, ValidationObserver },

  inject: ['media'],

  props: {
    canUpdate: { type: Boolean, default: false },
  },

  data() {
    return {
      isOpen: false,
      isEditMode: false,
      settingsIsSent: false,

      automaticInvoiceNumbering: false,
      invoiceNumberTemplates: [],

      services: {
        list: [],
        isLoading: false,
      },
    };
  },

  computed: {
    serviceTypeTitle() {
      if (this.isEditMode) {
        return this.$t('invoice.settings_modal.edit_service_types');
      }

      return this.$t('invoice.settings_modal.service_types');
    },
  },

  watch: {
    async automaticInvoiceNumbering() {
      const isValid = await this.$refs.observer?.validate({ silent: true });
      this.isEditMode = false;

      if (this.isOpen && isValid) {
        this.patchSettings();
      }
    },
  },

  created() {
    this.initSettings();
  },

  methods: {
    open() {
      this.isOpen = true;
    },

    close() {
      this.isOpen = false;
      this.initSettings();
      this.isEditMode = false;
    },

    edit() {
      this.isEditMode = true;
    },

    async save() {
      const isValid = await this.$refs.observer.validate();

      if (isValid) {
        this.settingsIsSent = true;

        this.patchSettings()
          .then(() => {
            this.isEditMode = false;
          })
          .finally(() => {
            this.settingsIsSent = false;
          });
      }
    },

    async patchSettings() {
      if (this.$options.patchSettingsCancel) {
        this.$options.patchSettingsCancel();

        await flushPromises();
      }

      const cancelSource = client.getCancelToken();
      this.$options.patchSettingsCancel = cancelSource.cancel;

      try {
        await companyService.patchManagementCompanySettings({
          automaticInvoiceNumbering: this.automaticInvoiceNumbering,
          invoiceNumberTemplates: this.invoiceNumberTemplates,
          cancelToken: cancelSource.token,
        });
      } catch {
        // Перехватываем ошибку при отмене
      } finally {
        this.$options.patchSettingsCancel = null;
      }
    },

    async initSettings() {
      if (this.$options.initSettingsCancel) {
        this.$options.initSettingsCancel();

        await flushPromises();
      }

      this.services.isLoading = true;

      try {
        const cancelSource = client.getCancelToken();
        this.$options.initSettingsCancel = cancelSource.cancel;

        const services = await getServiceTypes({ cancelToken: cancelSource.token });
        const settings = await companyService.getManagementCompanySettings({ cancelToken: cancelSource.token });

        const finalSettings = services.map(service => {
          const foundSetting = settings.invoiceNumberTemplates.find(setting => setting.serviceType === service.value);

          if (foundSetting) return foundSetting;

          return {
            serviceType: service.value,
            prefix: '',
            initialNumbering: '00000000',
          };
        });

        this.invoiceNumberTemplates = finalSettings;

        this.automaticInvoiceNumbering = settings.automaticInvoiceNumbering;
        this.services.list = services;
      } catch {
        // Перехватываем ошибку при отмене
      } finally {
        this.$options.initSettingsCancel = null;
        this.services.isLoading = false;
      }
    },
  },

  initSettingsCancel: null,
  patchSettingsCancel: null,
};
</script>

<style lang="scss">
.invoices-settings-modal {
  &__button-stub {
    height: 36px;
  }
}
</style>
