<template>
  <div class="settings-modal-service">
    <h3 class="text-body-1 font-weight-medium">{{ title }}</h3>

    <v-row no-gutters>
      <v-col cols="12" md="7">
        <v-row no-gutters>
          <v-col class="pr-2" cols="6">
            <validation-provider v-slot="{ errors }" mode="lazy" rules="required|max:6">
              <text-field
                v-model="prefixModel"
                class="settings-modal-service__field"
                :class="{ 'settings-modal-service__field--readonly': readonly }"
                :disabled="disabled"
                :maxlength="$options.prefixMaxLength"
                :readonly="readonly"
                :error-messages="errors"
                :label="$t('invoice.settings_modal.basic_numbering')"
              />
            </validation-provider>
          </v-col>

          <v-col class="pl-2" cols="6">
            <validation-provider v-slot="{ errors }" mode="lazy" rules="required|max:8">
              <text-field
                v-model="initialNumberingModel"
                type="number"
                class="settings-modal-service__field"
                :min="0"
                :class="{ 'settings-modal-service__field--readonly': readonly }"
                :disabled="disabled"
                :error-messages="errors"
                :readonly="readonly"
                :maxlength="$options.initialNumberingMaxLength"
                :label="$t('invoice.settings_modal.initial_numbering')"
              >
                <template #prepend-inner>
                  <div class="settings-modal-service__slash">
                    /
                  </div>
                </template>
              </text-field>
            </validation-provider>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="5" class="d-flex flex-column" :class="media.isMobile ? 'align-start' : 'align-end'">
        <div class="text-caption" :class="{ 'text--disabled': disabled }">
          {{ $t('invoice.settings_modal.example') }}
        </div>

        <span class="text-body-1 black--text" :class="{ 'text--disabled': disabled }">
          {{ example }}
        </span>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// Components
import TextField from '@/components/TextField.vue';
import { ValidationProvider } from 'vee-validate';
import { createModelWrapper } from '@/utils/components';

export default {
  name: 'SettingsModalService',

  components: { TextField, ValidationProvider },

  inject: ['media'],

  props: {
    title: { type: String, required: true },
    readonly: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    prefix: { type: String, required: true },
    initialNumbering: { type: String, required: true },
  },

  computed: {
    prefixModel: createModelWrapper('prefix', 'update:prefix'),
    initialNumberingModel: createModelWrapper('initialNumbering', 'update:initialNumbering'),

    example() {
      return `${this.prefix} / ${this.initialNumbering}`;
    },
  },

  watch: {
    async initialNumberingModel(newValue, oldValue) {
      if (newValue.length < this.$options.initialNumberingMinLength) {
        await this.$nextTick();
        this.initialNumberingModel = newValue.padStart(oldValue.length || this.$options.initialNumberingMinLength, '0');
      }
    },
  },

  initialNumberingMaxLength: 8,
  initialNumberingMinLength: 6,
  prefixMaxLength: 6,
};
</script>

<style lang="scss">
.settings-modal-service {
  &__slash {
    height: 16px;
    padding: 4px 0;
    box-sizing: content-box;
  }

  &__field {
    &--readonly * {
      pointer-events: none !important;
    }
  }
}
</style>
