<template>
  <base-missing-data class="invoice-missing-data" :icon="$options.IconMissingDataInvoices" :message="message">
    <template v-if="isEmployee" #message>
      {{ message }}
    </template>

    <template v-else #message>
      {{ $t('invoice.no_bills') }}
    </template>
  </base-missing-data>
</template>

<script>
import { PAID, UNPAID, RECURRENCE } from '@/constants/entityStatuses';

import BaseMissingData from '@/components/BaseMissingData.vue';
import IconMissingDataInvoices from '@/components/MissingDataIcons/MissingDataInvoices.vue';

export default {
  name: 'InvoicesMissingData',

  components: { BaseMissingData },

  props: {
    isEmployee: { type: Boolean, default: false },
    status: { type: String, required: true },
  },

  computed: {
    isPaid() {
      return this.status === PAID;
    },

    isUnpaid() {
      return this.status === UNPAID;
    },

    isRecurrence() {
      return this.status === RECURRENCE;
    },

    message() {
      if (this.isEmployee) {
        if (this.isPaid) {
          return this.$t('invoice.no_paid_invoices');
        }

        if (this.isUnpaid) {
          return this.$t('invoice.no_unpaid_invoices');
        }

        if (this.isRecurrence) {
          return this.$t('invoice.no_recurrence_invoices');
        }

        return this.$t('invoice.employee_no_bills');
      }

      return this.$t('invoice.no_bills');
    },
  },

  IconMissingDataInvoices,
};
</script>
