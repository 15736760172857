<template>
  <base-table
    v-model="valueModel"
    class="invoices-table"
    hide-default-footer
    :show-select="isEmployee"
    :items="invoices"
    :loading="loading"
    :options.sync="optionsModel"
    :headers="headers"
    :server-items-length="serverItemsLength"
  >
    <template v-slot:[`header.data-table-select`]="{ props, on }">
      <v-menu v-model="dialogIsOpen" :close-on-content-click="false" offset-y>
        <template #activator="{ on: modalActivator }">
          <v-simple-checkbox
            v-bind="props"
            :ripple="false"
            color="primary"
            @click="selectAll(props, modalActivator)"
            v-on="on"
          />
        </template>
        <v-card max-width="352">
          <v-card-text class="text-subtitle-2">
            {{ $t('invoice.select_info') }}
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn text class="font-weight-bold" color="primary" @click="selectAllInPage"
              >{{ $t('button.all_in_page') }} ({{ pageInvoicesCount }})</v-btn
            >
            <v-btn text class="font-weight-bold" color="primary" @click="selectGlobal"
              >{{ $t('button.all') }} ({{ invoicesCount }})</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-menu>
    </template>

    <template v-slot:item="{ headers: itemHeaders, item, select, isSelected }">
      <table-item
        :is-employee="isEmployee"
        :is-recurrence="isRecurrence"
        :headers="itemHeaders"
        :is-selected="isSelected"
        :invoice="item"
        :payment-types="paymentTypes"
        :can-update="canUpdate"
        :has-unpaid="hasUnpaid"
        @select="select"
        @download="downloadInvoice"
        @close-overlay="closeOverlay"
        @delete="deleteInvoice"
        @save-payment-type="savePaymentType"
      />
    </template>
  </base-table>
</template>

<script>
// Utils
import { createModelWrapper } from '@/utils/components';

// Components
import BaseTable from '@/components/BaseTable.vue';
import TableItem from './TableItem.vue';

export default {
  name: 'InvoicesTable',

  components: { TableItem, BaseTable },

  inject: ['media'],

  props: {
    value: { type: Array, required: true },
    invoices: { type: Array, required: true },
    paymentTypes: { type: Array, required: true },
    options: { type: Object, required: true },
    headers: { type: Array, required: true },
    pageCount: { type: Number, required: true },
    invoicesCount: { type: Number, required: true },
    pageInvoicesCount: { type: Number, required: true },
    isEmployee: { type: Boolean, required: true },
    isRecurrence: { type: Boolean, required: true },
    loading: { type: Boolean, default: false },
    serverItemsLength: { type: [Boolean, Number], default: false },
    canUpdate: { type: Boolean, default: false },
  },

  data() {
    return {
      dialogIsOpen: false,
    };
  },

  computed: {
    valueModel: createModelWrapper('value', 'input'),
    optionsModel: createModelWrapper('options', 'update:options'),
    hasUnpaid() {
      for (let i = 0; i < this.invoices.length; i += 1) {
        if (this.invoices[i].status === 'unpaid') return true;
      }
      return false;
    },
  },

  methods: {
    selectAll(props) {
      if (this.media.isMobile) {
        this.valueModel = this.invoices;

        if (this.pageCount > 1) {
          this.dialogIsOpen = true;
        }

        return;
      }

      if (!props?.value && this.pageCount > 1) {
        this.dialogIsOpen = true;
      }
    },

    selectAllInPage() {
      this.$emit('global-select', false);
      this.closeDialog();
    },

    selectGlobal() {
      this.$emit('global-select', true);
      this.closeDialog();
    },

    closeDialog() {
      this.dialogIsOpen = false;
    },

    downloadInvoice(invoice) {
      this.$emit('download-invoice', invoice);
    },

    closeOverlay(invoice) {
      this.$emit('close-overlay', invoice);
    },

    deleteInvoice(invoice) {
      this.$emit('delete-invoice', invoice);
    },

    savePaymentType(invoice, paymentTypeComment, paymentType) {
      this.$emit('save-payment-type', invoice, paymentTypeComment, paymentType);
    },
  },
};
</script>
