<template>
  <svg viewBox="0 0 252 251" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="125.988" cy="125.152" r="125.152" fill="#80BAFF" fill-opacity=".8" />
    <rect x="52" y="98" width="89" height="65" rx="1" fill="#E6EBFF" />
    <rect x="69" y="55" width="89" height="43" rx="1" fill="#E6EBFF" />
    <path d="M136 66.667 161 69v-4l-25 1.667Z" fill="#94C3FA" />
    <path
      d="M173.601 70.797c-1.991 8.012 1.564 15.792 7.941 17.377 6.378 1.584 13.161-3.626 15.152-11.638 1.991-8.013.496-24.082-5.881-25.667-6.377-1.585-15.221 11.916-17.212 19.928ZM125.142 89.188c4.62 8.001 13.51 11.517 19.857 7.852 6.348-3.665 7.748-13.121 3.129-21.122s-18.289-19.795-24.636-16.13c-6.348 3.664-2.969 21.4 1.65 29.4ZM79.889 133.393c1.717 2.974 5.021 4.281 7.38 2.919 2.36-1.362 2.88-4.877 1.163-7.851-1.717-2.974-6.797-7.357-9.157-5.995-2.359 1.362-1.103 7.954.614 10.927Z"
      fill="#00008D"
    />
    <path
      d="M202.95 117.151C202.95 90.558 185.5 69 163.975 69 142.45 69 125 90.558 125 117.151v.213c.004 1.276.077 2.487.211 3.636H101.5C85.208 121 72 134.208 72 150.5c0 2.669.355 5.256 1.019 7.715A9.457 9.457 0 0 0 72 162.5v18a9.5 9.5 0 0 0 9.5 9.5 9.5 9.5 0 0 0 9.5-9.5v-2.423a29.351 29.351 0 0 0 7 1.718v.705a9.5 9.5 0 0 0 9.5 9.5 9.5 9.5 0 0 0 9.5-9.5v-.5h18v.5a9.5 9.5 0 0 0 9.5 9.5 9.5 9.5 0 0 0 9.5-9.5v-.5h15.5c.842 0 1.676-.035 2.5-.104v.604a9.5 9.5 0 0 0 9.5 9.5 9.5 9.5 0 0 0 9.5-9.5v-9.801a29.397 29.397 0 0 0 8-20.199c0-4.99-1.239-9.691-3.427-13.812 3.926-3.19 5.953-7.866 6.844-12.236.286-1.404.456-2.777.533-4.059v-3.242Z"
      fill="#00008D"
    />
    <path
      d="M73.019 158.215A29.54 29.54 0 0 1 72 150.5v12c0-1.542.367-2.998 1.019-4.285ZM195.573 136.688A29.366 29.366 0 0 1 199 150.5l3.417-26.048c-.891 4.37-2.918 9.046-6.844 12.236Z"
      fill="#00008D"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M202.786 122c-7.788 11.751-21.132 19.5-36.286 19.5-19.126 0-35.37-12.344-41.198-29.5 0 0-.802 2.5-.802 4.5s.5 4.577.5 4.577c5.552 17.635 22.032 30.423 41.5 30.423 14.051 0 26.546-6.662 34.499-17 1.6-3.2 1.858-9.667 1.787-12.5Z"
      fill="#002FFF"
    />
    <rect x="141" y="124" width="46" height="59" rx="5" fill="#E6EBFF" />
    <rect x="144" y="127" width="40" height="53" rx="2" fill="#fff" />
    <path
      d="M169.867 149.563c-.007-.004-.015-.004-.022-.008l.002-.002-1.84-.877v.004a.271.271 0 0 0-.194-.013.273.273 0 0 0-.151.12c-.006.011-.007.022-.011.033-.536 1.007-1.666 1.806-2.946 1.806-1.593 0-2.864-.81-3.508-2.091h4.618c.006 0 .011.003.017.003.15 0 .272-.121.272-.27v-.95a.269.269 0 0 0-.262-.268v-.004h-5.1c-.019-.169-.019-.358-.019-.546 0-.207 0-.395.038-.603h5.068l.004.001.003-.001h.007v-.001a.275.275 0 0 0 .185-.082.266.266 0 0 0 .076-.186v-.951a.27.27 0 0 0-.271-.27l-.011.002h-4.606c.664-1.243 1.934-2.035 3.489-2.035 1.29 0 2.427.81 2.958 1.828l.003-.001a.269.269 0 0 0 .341.103l.001.004 1.839-.877-.001-.003c.006-.003.014-.003.021-.007a.267.267 0 0 0 .106-.348l.004-.001-.01-.017-.001-.003-.002-.002c-.894-1.575-2.503-3.05-5.259-3.05-3.091 0-5.594 1.714-6.447 4.409h-.976l-.01-.002a.271.271 0 0 0-.272.27v.95c0 .15.122.27.272.27h.701c-.019.207-.019.395-.019.603 0 .188 0 .376.019.546h-.701v.001a.271.271 0 0 0-.272.27v.951c0 .149.122.27.272.27.006 0 .011-.004.017-.004h.95c.834 2.732 3.356 4.466 6.466 4.466 2.751 0 4.36-1.507 5.254-3.061l.007-.008.003-.008.008-.013-.003-.001a.266.266 0 0 0-.107-.346Z"
      fill="#C7D6FF"
    />
    <ellipse cx="152" cy="104" rx="5" ry="6" transform="rotate(180 152 104)" fill="#fff" />
    <circle cx="156.5" cy="101.5" r="4.5" transform="rotate(180 156.5 101.5)" fill="#00008D" />
    <ellipse cx="177" cy="103" rx="5" ry="6" transform="rotate(180 177 103)" fill="#fff" />
    <circle cx="180.5" cy="101.5" r="4.5" transform="rotate(180 180.5 101.5)" fill="#00008D" />
    <circle cx="158" cy="124" r="8" fill="#00008D" />
    <circle cx="171" cy="123" r="8" fill="#00008D" />
    <path d="M160 118.5c0 1.381 5 2.5 5 2.5s5-1.119 5-2.5-2.239-2.5-5-2.5-5 1.119-5 2.5Z" fill="#FF5252" />
    <path d="M94 88.333 69 86v4l25-1.667Z" fill="#94C3FA" />
    <rect x="152" y="159" width="24" height="3" rx="1.5" fill="#E6EBFF" />
    <rect x="152" y="165" width="24" height="3" rx="1.5" fill="#E6EBFF" />
    <path d="M72 136.2 52 135v3l20-1.8ZM112 109.5 52 106v6l60-2.5Z" fill="#94C3FA" />
    <path
      d="M232.53 44.14c2.915-.494 5.592.963 5.979 3.253.388 2.29-1.661 4.546-4.575 5.04-2.915.493-8.608-.453-8.995-2.743-.388-2.29 4.677-5.057 7.591-5.55Z"
      fill="#E6EBFF"
    />
    <path
      d="M231.743 55.606c.493 2.915-.963 5.592-3.253 5.98-2.29.387-4.547-1.662-5.04-4.576-.493-2.915.453-8.607 2.743-8.995 2.29-.387 5.057 4.677 5.55 7.591Z"
      fill="#E6EBFF"
    />
    <path
      d="M221.837 56.03c-2.915.493-5.591-.963-5.979-3.253-.387-2.29 1.661-4.546 4.576-5.04 2.914-.493 8.607.453 8.994 2.743.388 2.29-4.676 5.057-7.591 5.55Z"
      fill="#E6EBFF"
    />
    <path
      d="M222.624 44.56c-.493-2.915.963-5.592 3.253-5.98 2.29-.387 4.547 1.662 5.04 4.576.493 2.915-.453 8.607-2.743 8.995-2.29.387-5.057-4.677-5.55-7.591Z"
      fill="#E6EBFF"
    />
    <rect
      x="231.805"
      y="52.332"
      width="1.552"
      height="11.726"
      rx=".776"
      transform="rotate(126.604 231.805 52.332)"
      fill="#C7D6FF"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconMissingDataInvoices',
};
</script>
