<template>
  <v-btn text class="download-progress d-flex" @click="click">
    <v-progress-circular :rotate="-90" size="40" :width="3" :value="progress" :color="progressColor">
      <v-icon size="24" :color="iconColor">
        <template v-if="isError">
          mdi-reload
        </template>
        <template v-else>
          mdi-download
        </template>
      </v-icon>
    </v-progress-circular>

    <div class="d-flex flex-column justify-space-around ml-2 align-start">
      <div class="primary--text text-caption font-weight-bold">{{ fileName }}</div>
      <div class="download-progress__progress text-caption primary--text">{{ progress }}%</div>
    </div>
  </v-btn>
</template>

<script>
export default {
  name: 'DownloadProgress',

  props: {
    progress: { type: Number, required: true },
    dark: { type: Boolean, default: false },
    fileName: { type: String, required: true },
    isError: { type: Boolean, default: false },
  },

  computed: {
    iconColor() {
      if (this.dark) return 'white';
      return 'blue20';
    },

    progressColor() {
      if (this.isError) return 'error';
      return '#002FFF28';
    },
  },

  methods: {
    click() {
      if (this.isError) {
        this.$emit('repeat');
      }
    },
  },
};
</script>

<style lang="scss">
.download-progress {
  height: 40px;

  &__progress {
    opacity: 0.5;
  }

  .v-progress-circular__overlay {
    transition: none;
  }
}
</style>
