<template>
  <filled-mobile-list class="invoices-employee-recurrent-invoice" v-bind="$attrs">
    <template #header="">
      <v-col class="py-3 px-2" cols="12">
        <view-list-field class="invoices-employee-recurrent-invoice pr-2" :label="$t('invoice.recurrence_period')">
          {{ recurrencePeriod }}
        </view-list-field>
      </v-col>
    </template>

    <download-overlay
      :is-loading="isLoading"
      :file-name="fileName"
      :progress="progress"
      :is-error="isError"
      @repeat="detailed"
      @close-overlay="closeOverlay"
    />

    <v-row no-gutters>
      <v-col cols="7">
        <view-list-field inline class="invoices-employee-recurrent-invoice pr-2" :label="$t('invoice.project')">
          {{ project }}
        </view-list-field>
      </v-col>

      <v-col cols="5">
        <view-list-field inline :label="$t('invoice.unit')">
          {{ unit }}
        </view-list-field>
      </v-col>
    </v-row>

    <div class="my-5">
      <div class="text-body-1 font-weight-bold">{{ clientName }}</div>
      <div class="text-caption text--secondary">{{ $t('invoice.invoice_for') }}</div>
    </div>

    <div class="my-5">
      <div class="text-body-1 font-weight-bold">{{ service }}</div>
      <div class="text-caption text--secondary">{{ companyName }}</div>
    </div>

    <view-list-field class="mb-5" inline :label="$t('invoice.first_invoice')">
      {{ firstInvoice }}
    </view-list-field>

    <view-list-field class="mb-5" inline :label="$t('invoice.last_invoice')">
      {{ lastInvoice }}
    </view-list-field>

    <div class="d-flex">
      <div class="text-body-1 mr-2 font-weight-bold">{{ totalAmount }} {{ currencySymbol }}</div>
    </div>

    <div class="invoices-employee-recurrent-invoice__actions">
      <glass-btn
        exact
        min-width="44"
        width="44"
        color="white"
        :to="{ name: $options.INVOICES_RECURRENCE_EDIT, params: { id: invoiceId } }"
      >
        <v-icon color="primary">mdi-pencil</v-icon>
      </glass-btn>

      <glass-btn class="ml-2" min-width="44" width="44" color="white" @click="deleteInvoice">
        <v-icon color="primary">mdi-delete</v-icon>
      </glass-btn>
    </div>
  </filled-mobile-list>
</template>

<script>
// Constants
import { INVOICES_RECURRENCE_EDIT } from '@/constants/routes';

// Components
import ViewListField from '@/components/ViewListField.vue';
import GlassBtn from '@/components/GlassBtn.vue';
import FilledMobileList from '@/components/FilledMobileList.vue';
import DownloadOverlay from './DownloadOverlay.vue';

export default {
  name: 'InvoicesEmployeeRecurrenceInvoice',

  components: { FilledMobileList, ViewListField, GlassBtn, DownloadOverlay },

  props: {
    invoiceId: { type: Number, required: true },
    project: { type: String, required: true },
    unit: { type: String, required: true },
    clientName: { type: String, required: true },
    recurrencePeriod: { type: String, required: true },
    service: { type: String, required: true },
    companyName: { type: String, default: '' },
    totalAmount: { type: String, required: true },
    currencySymbol: { type: String, default: '' },
    isLoading: { type: Boolean, default: false },
    progress: { type: Number, default: 0 },
    fileName: { type: String, default: '' },
    isError: { type: Boolean, default: false },
    firstInvoice: { type: String, default: '' },
    lastInvoice: { type: String, default: '' },
  },

  methods: {
    deleteInvoice() {
      this.$emit('delete');
    },

    detailed() {
      this.$emit('detailed');
    },

    closeOverlay() {
      this.$emit('close-overlay');
    },
  },

  INVOICES_RECURRENCE_EDIT,
};
</script>

<style lang="scss">
.invoices-employee-recurrent-invoice {
  &__actions {
    display: block;
    position: absolute;
    top: 14px;
    right: 8px;
  }

  &__button {
    opacity: 0.5;
  }

  &__vat {
    min-width: 70px;
  }
}
</style>
