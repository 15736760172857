<template>
  <filled-mobile-list class="invoices-employee-invoice" :date="date" :period="period" v-bind="$attrs">
    <download-overlay
      :is-loading="isLoading"
      :file-name="fileName"
      :progress="progress"
      :is-error="isError"
      @repeat="detailed"
      @close-overlay="closeOverlay"
    />

    <v-row no-gutters>
      <v-col cols="7">
        <view-list-field inline class="invoices-employee-invoice pr-2" :label="$t('invoice.project')">
          {{ project }}
        </view-list-field>
      </v-col>

      <v-col cols="5">
        <view-list-field inline :label="$t('invoice.unit')">
          {{ unit }}
        </view-list-field>
      </v-col>
    </v-row>

    <div class="my-5">
      <div class="text-body-1 font-weight-bold">{{ clientName }}</div>
      <div class="text-caption text--secondary">{{ $t('invoice.invoice_for') }}</div>
    </div>

    <div class="my-5">
      <div class="text-body-1 font-weight-bold">{{ service }}</div>
      <div class="text-caption text--secondary">{{ companyName }}</div>
    </div>

    <div class="d-flex">
      <div class="text-body-1 mr-2 font-weight-bold">{{ totalAmount }} {{ currencySymbol }}</div>
      <button class="invoices-employee-invoice__button primary--text text-decoration-underline" @click="detailed">
        {{ $t('invoice.details') }}
      </button>

      <div class="text--secondary flex-grow-1 text-end">
        <template v-if="isPaid">
          {{ paymentDate }}
        </template>
        <template v-else-if="isUnpaid">
          {{ $t('invoice.unpaid') }}
          <glass-btn
            class="invoices-employee-invoice__delete-button"
            min-width="30"
            width="30"
            height="30"
            color="white"
            @click="deleteInvoice"
          >
            <v-icon color="primary">mdi-delete</v-icon>
          </glass-btn>
        </template>
        <template v-else>
          {{ $t('invoice.pending') }}
        </template>
      </div>
    </div>

    <div class="mt-5">
      <div v-if="isPaid" class="text-caption text--secondary">{{ $t('invoice.payment_type') }}</div>
      <div class="d-flex justify-space-between align-center">
        <div v-if="isUnpaid" class="text-body-1">{{ $t('invoice.payment_type') }}</div>
        <div>
          <div v-if="isPaid" class="text-body-1 font-weight-bold">
            <span v-if="isAutoPaid">{{ $t('payment_type.application') }}</span>
            <span v-else-if="isPaid">{{ $t(paymentType) }}</span>
          </div>
          <div v-if="isPaid" class="text-caption text--secondary mobile-payment-type-comment">
            {{ paymentComment }}
          </div>
        </div>
        <v-menu v-if="!isAutoPaid" v-model="menu" left :offset-x="true" :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
            <icon-button :width="36" class="ml-3" v-bind="attrs" v-on="on">
              <v-icon color="primary">mdi-chevron-down</v-icon>
            </icon-button>
          </template>

          <v-list class="payment-type-list">
            <v-list-item-group v-model="paymentTypeSelect" color="primary">
              <v-list-item v-if="isPaid">
                <v-list-item-content>
                  <v-list-item-title>{{ $t('payment_type.unpaid') }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                v-for="paymentTypeItem in paymentTypes"
                :key="paymentTypeItem.value"
                :value="paymentTypeItem.value"
              >
                <v-list-item-content>
                  <v-list-item-title>{{ $t(paymentTypeItem.name) }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
            <v-list-item>
              <v-text-field
                v-model="paymentTypeComment"
                :placeholder="$t('label.comment')"
                maxlength="64"
                counter="64"
                class="mb-3"
              ></v-text-field>
            </v-list-item>
            <div class="text-center mb-2">
              <v-btn color="primary" @click="savePaymentType">{{ $t('button.save') }}</v-btn>
            </div>
          </v-list>
        </v-menu>
      </div>
    </div>
  </filled-mobile-list>
</template>

<script>
// Components
import ViewListField from '@/components/ViewListField.vue';
import GlassBtn from '@/components/GlassBtn.vue';
import FilledMobileList from '@/components/FilledMobileList.vue';
import IconButton from '@/components/IconButton.vue';
import DownloadOverlay from './DownloadOverlay.vue';

const INVOICE_STATUSES = {
  UNPAID: 'unpaid',
  PENDING: 'pending',
  PAID: 'paid',
};

export default {
  name: 'InvoicesClientInvoice',

  components: { FilledMobileList, ViewListField, GlassBtn, DownloadOverlay, IconButton },

  props: {
    paymentTypes: { type: Array, required: true },
    paymentTypeId: { type: Number, default: null },
    paymentComment: { type: String, default: '' },
    project: { type: String, required: true },
    unit: { type: String, required: true },
    invoiceId: { type: Number, required: true },
    clientName: { type: String, required: true },
    date: { type: String, required: true },
    period: { type: String, required: true },
    service: { type: String, required: true },
    companyName: { type: String, required: true },
    status: { type: String, required: true },
    totalAmount: { type: String, required: true },
    currencySymbol: { type: String, required: true },
    paymentDate: { type: String, default: '' },
    isLoading: { type: Boolean, default: false },
    progress: { type: Number, default: 0 },
    fileName: { type: String, default: '' },
    isError: { type: Boolean, default: false },
  },

  data: () => ({
    menu: false,
    paymentTypeSelect: '',
    paymentTypeComment: '',
  }),

  computed: {
    isPaid() {
      return this.status === INVOICE_STATUSES.PAID;
    },

    isAutoPaid() {
      return this.status === INVOICE_STATUSES.PAID && this.paymentTypeId === null;
    },

    isPending() {
      return this.status === INVOICE_STATUSES.PENDING;
    },

    isUnpaid() {
      return this.status === INVOICE_STATUSES.UNPAID;
    },

    paymentType() {
      let paymentType = '';

      if (this.isPaid) {
        for (let i = 0; i < this.paymentTypes.length; i += 1) {
          if (this.paymentTypes[i].value === this.paymentTypeId) {
            paymentType = this.paymentTypes[i].name;
            break;
          }
        }
      }

      return paymentType;
    },
  },

  watch: {
    paymentTypeId(newVal) {
      this.paymentTypeSelect = newVal;
    },
    paymentComment(newVal) {
      this.paymentTypeComment = newVal;
    },
  },

  mounted() {
    this.paymentTypeSelect = this.paymentTypeId;
    this.paymentTypeComment = this.paymentComment;
  },

  methods: {
    deleteInvoice() {
      this.$emit('delete');
    },

    detailed() {
      this.$emit('detailed');
    },

    closeOverlay() {
      this.$emit('close-overlay');
    },

    savePaymentType() {
      this.menu = false;
      this.$emit('save-payment-type', this.paymentTypeSelect || null, this.paymentTypeComment, this.invoiceId);
    },
  },
};
</script>

<style lang="scss">
.invoices-employee-invoice {
  &__button {
    opacity: 0.5;
  }

  &__change-payment-type-button {
    position: absolute;
    top: 20px;
    right: 8px;
  }

  &__delete-button {
    padding: 0 !important;
    position: absolute;
    top: 5px;
    right: 5px;

    .v-icon.v-icon {
      font-size: 20px;
    }
  }
}
.payment-type-list {
  .v-input--selection-controls__input {
    position: absolute !important;
    opacity: 0 !important;
    overflow: hidden !important;
    width: 0 !important;
    height: 0 !important;
    pointer-events: none !important;
  }

  .v-input--selection-controls .v-input__slot,
  .v-input--selection-controls .v-radio,
  .v-input {
    width: 100%;
  }

  .theme--light.v-text-field > .v-input__control > .v-input__slot:before {
    border-color: rgba(0, 0, 196, 0.15) !important;
  }
}

.payment-type-active {
  background: $--blue-color-40 !important;

  label {
    color: $--blue-color-10 !important;
  }
}

.mobile-payment-type-comment {
  word-break: break-word;
  max-width: 250px;
}
</style>
