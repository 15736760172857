<template>
  <v-switch
    v-model="modelWrapper"
    class="base-switch mt-0"
    :class="{ 'base-switch--reverse': reverse, 'base-switch--expand': expand }"
    v-bind="$attrs"
    v-on="inheritedListeners"
  />
</template>

<script>
// Utils
import { createModelWrapper, getInheritedListeners } from '@/utils/components';

export default {
  name: 'BaseSwitch',

  inheritAttrs: false,

  props: {
    value: { type: Boolean, default: false },
    reverse: { type: Boolean, default: false },
    expand: { type: Boolean, default: false },
  },

  computed: {
    modelWrapper: createModelWrapper('value', 'input'),
    inheritedListeners: getInheritedListeners(['input']),
  },
};
</script>

<style lang="scss">
.base-switch {
  .v-input--switch__track.theme--light.primary--text {
    color: $--blue-color-60 !important;
    caret-color: $--blue-color-60 !important;
  }

  &--reverse .v-input__slot {
    flex-direction: row-reverse;
    justify-content: flex-end;
    .v-application--is-ltr & {
      .v-input--selection-controls__input {
        margin-right: 0;
        margin-left: 8px;
      }
    }
    .v-application--is-rtl & {
      .v-input--selection-controls__input {
        margin-left: 0;
        margin-right: 8px;
      }
    }
  }

  &--expand .v-input__slot {
    // justify-content: space-between;
    .v-label {
      display: block;
      flex: 1;
    }
  }

  &--expand {
    width: 100%;
  }
}
</style>
