<template>
  <filled-mobile-list class="invoices-client-invoice" :date="date" :period="period" v-bind="$attrs">
    <download-overlay
      :is-loading="isLoading"
      :file-name="fileName"
      :progress="progress"
      :is-error="isError"
      @repeat="detailed"
      @close-overlay="closeOverlay"
    />

    <div class="d-flex">
      <view-list-field inline class="invoices-client-invoice__unit pr-2" :label="$t('invoice.unit')">
        {{ unit }}
      </view-list-field>
    </div>

    <div class="mt-5 mb-6">
      <div class="text-body-1 font-weight-bold">{{ service }}</div>
      <div class="text-caption text--secondary">{{ companyName }}</div>
    </div>

    <div class="d-flex">
      <div class="text-body-1 mr-2 font-weight-bold">{{ totalAmount }} {{ $t('invoice.euro') }}</div>
      <button class="invoices-client-invoice__button primary--text text-decoration-underline" @click="detailed">
        {{ $t('invoice.details') }}
      </button>

      <v-btn
        v-if="isUnpaid"
        class="invoices-client-invoice__pay-button"
        color="primary"
        :to="{ name: $options.INVOICES_PAYMENT, params: { id: paymentId } }"
      >
        {{ $t('button.pay') }}
      </v-btn>
      <div v-else class="text--secondary flex-grow-1 text-end">
        <template v-if="isPaid">
          {{ paymentDate }}
        </template>
        <template v-else>
          {{ $t('invoice.pending') }}
        </template>
      </div>
    </div>
  </filled-mobile-list>
</template>

<script>
// Constants
import { INVOICES_PAYMENT } from '@/constants/routes';

// Components
import ViewListField from '@/components/ViewListField.vue';
import FilledMobileList from '@/components/FilledMobileList.vue';
import DownloadOverlay from './DownloadOverlay.vue';

const INVOICE_STATUSES = {
  UNPAID: 'unpaid',
  PENDING: 'pending',
  PAID: 'paid',
};

export default {
  name: 'InvoicesClientInvoice',

  components: { FilledMobileList, ViewListField, DownloadOverlay },

  props: {
    date: { type: String, required: true },
    period: { type: String, required: true },
    unit: { type: String, required: true },
    service: { type: String, required: true },
    companyName: { type: String, required: true },
    status: { type: String, required: true },
    totalAmount: { type: String, required: true },
    paymentDate: { type: String, default: '' },
    paymentId: { type: [Number, String], required: true },
    isLoading: { type: Boolean, default: false },
    progress: { type: Number, default: 0 },
    fileName: { type: String, default: '' },
    isError: { type: Boolean, default: false },
  },

  computed: {
    isPaid() {
      return this.status === INVOICE_STATUSES.PAID;
    },

    isPending() {
      return this.status === INVOICE_STATUSES.PENDING;
    },

    isUnpaid() {
      return this.status === INVOICE_STATUSES.UNPAID;
    },
  },

  methods: {
    detailed() {
      this.$emit('detailed');
    },

    closeOverlay() {
      this.$emit('close-overlay');
    },
  },

  INVOICES_PAYMENT,
};
</script>

<style lang="scss">
.invoices-client-invoice {
  &__button {
    opacity: 0.5;
  }

  &__pay-button {
    position: absolute;
    right: 8px;
    bottom: 10px;
  }

  &__unit {
    max-width: calc(100% - 70px);
  }
}
</style>
